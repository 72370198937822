import {
  FETCH_LOYALTY_LION_AUTH_REQUEST,
  FETCH_LOYALTY_LION_AUTH_SUCCESS,
  FETCH_LOYALTY_LION_AUTH_FAILURE,
} from './actionTypes';

import { ExternalAuthActions, ExternalAuthState } from './types';

const initialState: ExternalAuthState = {
  pending: false,
  data: {},
  error: null,
};

export const externalReducer = (state = initialState, action: ExternalAuthActions) => {
  switch (action.type) {
    case FETCH_LOYALTY_LION_AUTH_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_LOYALTY_LION_AUTH_SUCCESS:
      return {
        ...state,
        pending: false,
        data: {
          loyaltylion: action.payload.auth.data,
        },
        error: null,
      };
    case FETCH_LOYALTY_LION_AUTH_FAILURE:
      return {
        ...state,
        pending: false,
        data: {},
        error: action.payload.error,
      };

    case 'persist/REHYDRATE':
      return {
        ...state,
        data:
          action.payload && action.payload.auth && action.payload.auth.data
            ? action.payload.auth.data
            : {},
      };
    default:
      return {
        ...state,
      };
  }
};
