import { ToastOptions } from 'react-toastify';
import { PetActivity, PetBody } from '../types/types';
import confetti from 'canvas-confetti';

export const COUNTRIES = [
  { code: 'AT', translation: 'GLOBAL.COUNTRY.AT' },
  { code: 'DE', translation: 'GLOBAL.COUNTRY.DE' },
];

export const REDIRECT_URL = 'https://www.hellobello.at/a/loop_subscriptions/get-subscription-link';

export const FRESH_PRODUCTS = [
  { code: 'CG', translation: 'ECOMMERCE.PRODUCTS.FRESH.CG.TITLE' },
  { code: 'GS', translation: 'ECOMMERCE.PRODUCTS.FRESH.GS.TITLE' },
  { code: 'RT', translation: 'ECOMMERCE.PRODUCTS.FRESH.RT.TITLE' },
];

export const TOAST_CONFIG: ToastOptions = {
  position: 'bottom-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: false,
  pauseOnHover: true,
};

export const DEFAULT_TIMEZONE = 'Europe/Berlin';

export const PET_ACTIVITIES = [
  {
    key: 'calm',
    value: PetActivity.calm,
    title: 'ruhig',
    description: 'Unter 1 Stunde pro Tag',
  },
  {
    key: 'normal',
    value: PetActivity.normal,
    title: 'normal aktiv',
    description: '1-2 Stunden pro Tag',
  },
  {
    key: 'active',
    value: PetActivity.active,
    title: 'sehr aktiv',
    description: '2+ Stunden pro Tag',
  },
];

export const PET_BODIES = [
  {
    key: 'skinny',
    value: PetBody.skinny,
    title: 'Untergewicht',
  },
  {
    key: 'ideal',
    value: PetBody.ideal,
    title: 'Idealgewicht',
  },
  {
    key: 'chunky',
    value: PetBody.chunky,
    title: 'Übergewicht',
  },
];

export const rewardConfetti = (x: number, y: number, offsetX: number) => {
  const isMobile = window.innerWidth <= 768;

  const particleCount = isMobile ? 100 : 200;
  const spread = isMobile ? 20 : 40;

  confetti({
    particleCount,
    spread,
    origin: { x: x + offsetX, y },
  });
};
