import {
  FETCH_LOYALTY_LION_AUTH_REQUEST,
  FETCH_LOYALTY_LION_AUTH_SUCCESS,
  FETCH_LOYALTY_LION_AUTH_FAILURE,
} from './actionTypes';
import {
  FetchLoyaltyLionAuthRequestPayload,
  FetchLoyaltyLionAuthRequest,
  FetchLoyaltyLionAuthSuccessPayload,
  FetchLoyaltyLionAuthSuccess,
  FetchLoyaltyLionAuthFailurePayload,
  FetchLoyaltyLionAuthFailure,
} from './types';

export const fetchLoyaltyLionAuthRequest = (
  payload: FetchLoyaltyLionAuthRequestPayload,
): FetchLoyaltyLionAuthRequest => ({
  type: FETCH_LOYALTY_LION_AUTH_REQUEST,
  payload,
});

export const fetchLoyaltyLionAuthSuccess = (
  payload: FetchLoyaltyLionAuthSuccessPayload,
): FetchLoyaltyLionAuthSuccess => ({
  type: FETCH_LOYALTY_LION_AUTH_SUCCESS,
  payload,
});

export const fetchLoyaltyLionAuthFailure = (
  payload: FetchLoyaltyLionAuthFailurePayload,
): FetchLoyaltyLionAuthFailure => ({
  type: FETCH_LOYALTY_LION_AUTH_FAILURE,
  payload,
});
