import { all, fork } from 'redux-saga/effects';

import { authSaga } from '../app/modules/auth/core/sagas';
import { addressesSaga } from 'src/app/modules/addresses/core/sagas';
import { customerSaga } from '../app/modules/customer/core/sagas';
import { ordersSaga } from '../app/modules/orders/core/sagas';
import { orderScheduleSaga } from '../app/modules/order-schedule/core/sagas';
import { paymentMethodsSaga } from '../app/modules/payment/core/sagas';
import { productsSaga } from '../app/modules/products/core/sagas';
import { subscriptionsSaga } from '../app/modules/subscriptions/core/sagas';
import { settingsSaga } from '../app/modules/settings/core/sagas';
import { externalSaga } from '@modules/external/core/sagas';
import { rootWatcher } from './rootWatcher';

export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(addressesSaga),
    fork(customerSaga),
    fork(ordersSaga),
    fork(orderScheduleSaga),
    fork(paymentMethodsSaga),
    fork(productsSaga),
    fork(subscriptionsSaga),
    fork(settingsSaga),
    fork(externalSaga),
    fork(rootWatcher),
  ]);
}
