import axios, { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  fetchLoyaltyLionAuthRequest,
  fetchLoyaltyLionAuthSuccess,
  fetchLoyaltyLionAuthFailure,
} from './actions';
import { FETCH_LOYALTY_LION_AUTH_REQUEST } from './actionTypes';

const fetchLoyaltyLion = async (params: any) =>
  await axios.post<any>('/api/v1/loyalty_lion/auth', params);

function* fetchLoyaltyLionAuthSaga(action: ReturnType<typeof fetchLoyaltyLionAuthRequest>) {
  try {
    const response: AxiosResponse<any> = yield call(() => fetchLoyaltyLion(action.payload.params));

    yield put(
      fetchLoyaltyLionAuthSuccess({
        auth: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      fetchLoyaltyLionAuthFailure({
        error: e.message,
      }),
    );
  }
}

export function* externalSaga() {
  yield all([takeLatest(FETCH_LOYALTY_LION_AUTH_REQUEST, fetchLoyaltyLionAuthSaga)]);
}
