import { createSelector } from 'reselect';
import { RootState } from 'src/store/store';

const getPending = (state: RootState) => state.external.pending;

const getExternal = (state: RootState) => state.external.data;

const getError = (state: RootState) => state.external.error;

export const getPendingSelector = createSelector(getPending, (pending) => pending);

export const getLoyaltyLionSelector = createSelector(
  getExternal,
  (external) => external.loyaltylion,
);

export const getErrorSelector = createSelector(getError, (error) => error);
