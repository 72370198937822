import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchLoyaltyLionAuthRequest } from '../core/actions';
import { getCustomerSelector } from '@modules/customer';
import { getLoyaltyLionSelector } from '../core/selectors';
import { LL_CLIENT_TOKEN } from '@constants/consts';
import { updateSettings } from '@modules/settings/core/actions';
import { getIsInitedLoyaltyLionSelector } from '@modules/settings/core/selectors';

// NOTE: it will break when using auto reload, just refresh it
export const useLoyaltyLion = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const ll = useSelector(getLoyaltyLionSelector);
  const customer = useSelector(getCustomerSelector);
  const isInitedLoyaltyLion = useSelector(getIsInitedLoyaltyLionSelector);

  const fetchAuthToken = () => {
    dispatch(
      fetchLoyaltyLionAuthRequest({
        params: {
          customer_id: customer.id,
          email: customer.email,
        },
      }),
    );
  };

  const init = () => {
    loyaltylion?.init({
      token: LL_CLIENT_TOKEN,
      customer: {
        id: customer?.id,
        email: customer?.email,
      },
      auth: {
        date: ll?.auth?.date,
        token: ll?.auth?.token,
      },
    });
  };

  useEffect(() => {
    if (customer) fetchAuthToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    if (
      (!isInitedLoyaltyLion && ll?.auth?.token && location.pathname.includes('loyalty')) ||
      (isInitedLoyaltyLion &&
        ll?.auth?.token &&
        !loyaltylion?.revision &&
        location.pathname.includes('loyalty'))
    ) {
      init();

      dispatch(
        updateSettings({
          initedLoyaltyLion: true,
        }),
      );
    }

    if (isInitedLoyaltyLion) loyaltylion?.ui?.refresh();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ll?.auth?.token, location.pathname, isInitedLoyaltyLion]);

  useEffect(() => {
    return () => {
      dispatch(
        updateSettings({
          initedLoyaltyLion: false,
        }),
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    fetchAuthToken,
    init,
  };
};
