import { FC, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { Aside } from './components/aside/Aside';
import { Footer } from './components/footer/Footer';
import { HeaderWrapper } from './components/header/HeaderWrapper';
import { Content } from './components/Content';
import { PageDataProvider } from './core';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { MenuComponent } from '../assets/ts/components';
import { WithChildren } from '../helpers';
import {
  themeModeSwitchHelper,
  useThemeMode,
} from '../partials/layout/theme-mode/ThemeModeProvider';
import {
  fetchFlowRewardBannerRequest,
  fetchSubscriptionsRequest,
} from '../../app/modules/subscriptions/core/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  getFlowBannerSelector,
  getLineItemSelector,
  getSubscriptionsSelector,
} from 'src/app/modules/subscriptions/core/selectors';
import { useSubscriptions } from 'src/app/modules/subscriptions/hooks/useSubscriptions';
import { fetchOrderHistoryRequest } from 'src/app/modules/orders/core/actions';
import { fetchOrderScheduleRequest } from '@modules/order-schedule/core/actions';
import { getCustomerSelector } from 'src/app/modules/customer/core/selectors';
import { fetchCustomerRequest } from 'src/app/modules/customer/core/actions';
import { fetchAddressesRequest } from 'src/app/modules/addresses/core/actions';
import { getAddressesSelector } from 'src/app/modules/addresses/core/selectors';
import { fetchPaymentMethodsRequest } from 'src/app/modules/payment/core/actions';
import { getPaymentMethodsSelector } from 'src/app/modules/payment/core/selectors';
import { getPendingSelector as getAuthPendingSelector } from '@modules/settings/core/selectors';
import { getPendingSelector as getOrdersPendingSelector } from '@modules/orders/core/selectors';
import { getPendingSelector as getSubscriptionsPendingSelector } from '@modules/subscriptions/core/selectors';
import { getPendingSelector as getAddressesPendingSelector } from '@modules/addresses/core/selectors';
import { getPendingSelector as getSettingsPendingSelector } from '@modules/settings/core/selectors';
import {
  getPendingSelector as getOrderSchedulePendingSelector,
  getOrderScheduleSelector,
} from '@modules/order-schedule/core/selectors';
import { Loading } from '@components/index';
import { useLoyaltyLion } from '@modules/external/hooks/useLoyaltyLion';
import { useAuth } from '@modules/auth';
import { fetchProductRequest } from '@modules/products/core/actions';
import {
  getProductSelector,
  getVariantProductInfoSelector,
} from '@modules/products/core/selectors';
import { useDelightedSurvey } from '@modules/external/hooks/useDelightedSurvey';
import { ToastifySuccess, ToastifyError, ToastifyClose } from 'src/helpers/ToastifyIcons';

export const MasterLayout: FC<WithChildren> = ({ children }) => {
  const dispatch = useDispatch();

  const { mode } = useThemeMode();
  const location = useLocation();

  const customer = useSelector(getCustomerSelector);
  const addresses = useSelector(getAddressesSelector);
  const paymentMethods = useSelector(getPaymentMethodsSelector);
  const subscriptions = useSelector(getSubscriptionsSelector);
  const orderSchedule = useSelector(getOrderScheduleSelector);
  const product = useSelector(getProductSelector);
  const lineItem = useSelector(getLineItemSelector);
  const flowBanner = useSelector(getFlowBannerSelector);

  const { defaultSubscription, setDefaultSubscription } = useSubscriptions();

  const currentSubscriptionID = useRef<number>(defaultSubscription?.id);
  const currentSubscriptionBillingDate = useRef<string>(defaultSubscription?.nextBillingDate);
  const currentSubscriptionShippingAddress = useRef<string>(
    defaultSubscription?.shippingAddress.address1 ?? '',
  );
  const variantProductInfo = useSelector(getVariantProductInfoSelector);

  const currentSubscriptionBillngInterval = useRef<string>(defaultSubscription?.nextBillingDate);

  const { refreshToken } = useAuth();

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  useEffect(() => {
    themeModeSwitchHelper(mode);
  }, [mode]);

  useEffect(() => {
    refreshToken();
    if (!subscriptions?.length) dispatch(fetchSubscriptionsRequest());
    if (!paymentMethods?.length) dispatch(fetchPaymentMethodsRequest());
    if (!customer) dispatch(fetchCustomerRequest());
    if (!addresses?.length) dispatch(fetchAddressesRequest());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!defaultSubscription && subscriptions.length) setDefaultSubscription(subscriptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptions]);

  useEffect(() => {
    if (currentSubscriptionID.current !== defaultSubscription?.id && defaultSubscription?.id) {
      dispatch(fetchOrderHistoryRequest({ subscriptionId: defaultSubscription?.id }));
      dispatch(fetchFlowRewardBannerRequest({ subscriptionId: defaultSubscription?.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSubscription?.id]);

  useEffect(() => {
    if (
      currentSubscriptionID.current !== defaultSubscription?.id ||
      (defaultSubscription?.nextBillingDate && !orderSchedule.length) ||
      currentSubscriptionBillingDate.current !== defaultSubscription?.nextBillingDate ||
      currentSubscriptionShippingAddress.current !== defaultSubscription?.shippingAddress.address1
    ) {
      dispatch(
        fetchOrderScheduleRequest({
          subscriptionId: defaultSubscription?.id,
        }),
      );

      currentSubscriptionBillingDate.current = defaultSubscription?.nextBillingDate;
      currentSubscriptionShippingAddress.current =
        defaultSubscription?.shippingAddress.address1 ?? '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSubscription?.nextBillingDate, defaultSubscription?.shippingAddress?.address1]);

  useEffect(() => {
    if (
      (!product || currentSubscriptionID.current !== defaultSubscription?.id) &&
      lineItem?.productShopifyId
    )
      dispatch(
        fetchProductRequest({
          productId: lineItem?.productShopifyId,
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSubscription?.id]);

  useEffect(() => {
    if (currentSubscriptionID.current !== defaultSubscription?.id)
      currentSubscriptionID.current = defaultSubscription?.id;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSubscription?.id]);

  useEffect(() => {
    if (defaultSubscription?.code) dispatch(fetchSubscriptionsRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSubscription?.code]);

  useEffect(() => {
    if (defaultSubscription?.id && !flowBanner) {
      dispatch(fetchFlowRewardBannerRequest({ subscriptionId: defaultSubscription.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSubscription?.id, flowBanner]);

  useEffect(() => {
    if (
      currentSubscriptionID.current !== defaultSubscription?.id ||
      (defaultSubscription?.billingPolicy?.interval && !orderSchedule.length) ||
      currentSubscriptionBillngInterval.current !== defaultSubscription?.billingPolicy?.interval
    ) {
      dispatch(
        fetchOrderScheduleRequest({
          subscriptionId: defaultSubscription?.id,
        }),
      );
      dispatch(fetchSubscriptionsRequest());

      currentSubscriptionBillngInterval.current = defaultSubscription?.billingPolicy?.interval;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSubscription?.billingPolicy?.interval]);

  useLoyaltyLion();
  useDelightedSurvey();

  const isPendingAuth = useSelector(getAuthPendingSelector);
  const isPendingOrders = useSelector(getOrdersPendingSelector);
  const isPendingSubscriptions = useSelector(getSubscriptionsPendingSelector);
  const isPendingAddresses = useSelector(getAddressesPendingSelector);
  const isPendingSettings = useSelector(getSettingsPendingSelector);
  const isPendingOrderSchedule = useSelector(getOrderSchedulePendingSelector);

  const isLoading =
    isPendingAuth ||
    isPendingOrders ||
    isPendingSubscriptions ||
    isPendingAddresses ||
    isPendingSettings ||
    isPendingOrderSchedule;

  return (
    <PageDataProvider>
      <ToastContainer
        icon={({ type }) => {
          if (type === 'success') return <ToastifySuccess />;
          if (type === 'error') return <ToastifyError />;
        }}
        closeButton={<ToastifyClose />}
      />
      {isLoading && <Loading />}
      <div className="flex flex-row page">
        <div className="flex flex-col flex-auto wrapper mt-[6.5rem] lg:mt-0" id="kt_wrapper">
          <HeaderWrapper />

          <div id="kt_content" className="flex flex-col content">
            {/* <Toolbar /> */}
            <div
              className="justify-center xl:mx-auto xl:container md:p-[1rem] lg:p-auto md:flex lg:items-start"
              id="kt_post"
            >
              <Aside />
              <Content>
                <div className=" main-content">{customer ? <Outlet /> : <p>loading</p>}</div>
                <Footer />
              </Content>
            </div>
          </div>
        </div>
      </div>
    </PageDataProvider>
  );
};
