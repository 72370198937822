import {
  SWITCH_SUBSCRIPTION_FAILURE,
  SWITCH_SUBSCRIPTION_REQUEST,
  SWITCH_SUBSCRIPTION_SUCCESS,
  UPDATE_SETTINGS,
} from './actionTypes';

import { SettingsActions, SettingsState } from './types';

const initialState: SettingsState = {
  pending: false,
  data: {
    defaultSubscription: null,
    initedLoyaltyLion: false,
    subscriptionOptions: {
      recipes: [],
      cycle: undefined,
      pension: undefined,
    },
  },
  error: null,
};

export const settingsReducer = (state = initialState, action: SettingsActions) => {
  switch (action.type) {
    case SWITCH_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case SWITCH_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          defaultSubscription: action.payload.subscription.id,
        },
        error: null,
      };
    case SWITCH_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case UPDATE_SETTINGS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case 'persist/REHYDRATE':
      return {
        ...state,
        data:
          action.payload && action.payload.settings && action.payload.settings.data
            ? action.payload.settings.data
            : {},
      };
    default:
      return {
        ...state,
      };
  }
};
