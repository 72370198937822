import { Button, Widget } from '@components/index';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';
import { reactivateSubscriptionRequest } from '@modules/subscriptions/core/actions';
import {
  getDefaultSubscriptionSelector,
  getPetSelector,
} from '@modules/subscriptions/core/selectors';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export const SubscriptionReactivation = () => {
  const pet = useSelector(getPetSelector);
  const dispatch = useDispatch();
  const defaultSubscription = useSelector(getDefaultSubscriptionSelector);

  const handleReactivateSubscription = () => {
    dispatch(
      reactivateSubscriptionRequest({
        id: defaultSubscription?.id,
      }),
    );
  };

  return (
    <Widget
      header={
        <p>
          <b>ABO WIEDER</b> AKTIVIEREN
        </p>
      }
    >
      <div className="bg-light-100 widget-recipe-wrapper">
        <div className="md:flex gap-[3rem] items-center justify-center font-bold mb-[1.5rem] lg:text-[1.6rem]">
          <div className="flex items-center justify-center">
            <KTSVG path={toAbsoluteUrl('/media/svg/misc/dog.svg')} className="mx-auto" />
          </div>

          <div>
            <p className="mb-[3rem]">Aktiviere dein Abo wieder. {pet?.name} wird sich freuen!</p>
            <Button onClick={handleReactivateSubscription}>
              <span className="text-[18px] button-small">Abo reaktivieren</span>
            </Button>
          </div>
        </div>
      </div>
    </Widget>
  );
};
