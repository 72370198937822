import { useIntl } from 'react-intl';
import { toAbsoluteUrl } from '../../../../helpers';
import { AsideMenuItem } from './AsideMenuItem';
import { AsideProfileMobile } from '../profile/AsideProfileMobile';
import { useSelector } from 'react-redux';
import { MENU_ITEMS } from '@constants/navi';
import { getPetSelector, getSubscriptionsSelector } from '@modules/subscriptions/core/selectors';
import { getCustomerSelector } from '@modules/customer';

export function AsideMenuMain() {
  const intl = useIntl();

  const pet = useSelector(getPetSelector);
  const subscriptions = useSelector(getSubscriptionsSelector);
  const customer = useSelector(getCustomerSelector);

  const filteredMenuItems = MENU_ITEMS.filter(
    (item) =>
      (item.id === 'subscriptions' && subscriptions?.length > 0) ||
      (item.id === 'loyalty' && customer?.tags?.includes('Loyalty_Lion')) ||
      (item.id !== 'subscriptions' && item.id !== 'loyalty'),
  );
  return (
    <>
      <AsideProfileMobile />
      {filteredMenuItems.map((item) => {
        const values = { petName: item?.pet && pet?.name ? pet?.name : 'Hund' };

        return (
          <AsideMenuItem
            key={item.id}
            to={item.to}
            title={intl.formatMessage({ id: item.title }, { ...values })}
            icon={toAbsoluteUrl(item.icon)}
            type={item.type}
          />
        );
      })}
    </>
  );
}
