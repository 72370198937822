export type MenuItem = {
  id: string;
  to: string;
  title: string;
  pet?: boolean;
  icon: string;
  type?: 'internal' | 'external';
};

export const MENU_ITEMS: MenuItem[] = [
  {
    id: 'dashboard',
    to: '/dashboard',
    title: 'MENU.DASHBOARD',
    icon: '/media/svg/aside/dashboard.svg',
  },
  {
    id: 'orders',
    to: '/orders',
    title: 'MENU.ORDERS',
    icon: '/media/svg/aside/order.svg',
  },
  {
    id: 'subscriptions',
    to: '/subscriptions',
    title: 'MENU.SUBSCRIPTIONS',
    pet: true,
    icon: '/media/svg/aside/bowl.svg',
  },
  {
    id: 'raf',
    to: '/raf',
    title: 'MENU.RAF',
    icon: '/media/svg/aside/gift.svg',
  },
  {
    id: 'loyalty',
    to: '/loyalty',
    title: 'MENU.LOYALTY',
    icon: '/media/svg/aside/bello-paw.svg',
  },
  {
    id: 'shop',
    to: '/shop',
    title: 'MENU.SHOP',
    icon: '/media/svg/aside/shop.svg',
  },

  {
    id: 'faq',
    to: 'https://hilfe.hellobello.de/de-DE',
    title: 'MENU.FAQ',
    icon: '/media/svg/general/question-mark-green.svg',
    type: 'external',
  },
];

export const NAVI: MenuItem[] = [
  ...MENU_ITEMS,
  {
    id: 'profile',
    to: '/profile',
    title: 'MENU.PROFILE',
    icon: '/media/svg/aside/paw.svg',
  },
];
