import {
  getDefaultSubscriptionSelector,
  getPetSelector,
} from '@modules/subscriptions/core/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { resumeSubscriptionRequest } from '@modules/subscriptions/core/actions';
import { Button, Widget } from '@components/index';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';

export const SubscriptionResume = () => {
  const pet = useSelector(getPetSelector);
  const dispatch = useDispatch();
  const defaultSubscription = useSelector(getDefaultSubscriptionSelector);

  const handleResumeSubscription = () => {
    dispatch(
      resumeSubscriptionRequest({
        id: defaultSubscription?.id,
      }),
    );
  };

  return (
    <Widget
      header={
        <p>
          <b>ABO</b> PAUSIERT
        </p>
      }
    >
      <div className="bg-light-100 widget-recipe-wrapper">
        <div className="md:flex gap-[3rem] items-center justify-center font-bold mb-[1.5rem] lg:text-[1.6rem]">
          <div className="flex items-center justify-center">
            <KTSVG path={toAbsoluteUrl('/media/svg/misc/dog.svg')} className="mx-auto" />
          </div>

          <div className="max-w-[60rem] text-left flex flex-col justify-center md:block">
            <p className="my-[3rem]">
              Dein Abo wird nach der gewählten Pausendauer automatisch fortgesetzt. Innerhalb des
              pausierten Zeitraums entstehen dir keine weiteren Kosten. Du erhältst von uns eine
              E-mail, sobald dein Abo wieder aktiv ist. Natürlich kannst du dein Abo für {pet?.name}{' '}
              jederzeit auch selbst wieder aktivieren:
            </p>
            <Button onClick={handleResumeSubscription}>
              <span className="text-[18px] button-small">Abo reaktivieren</span>
            </Button>
          </div>
        </div>
      </div>
    </Widget>
  );
};
